@use '../foundation/variables' as variables;
@use '../foundation/mixin' as mixin;

/*
top
*/

.p-top-mv {
  position: relative;
}
.p-top-mv-image {
  position: relative;
  z-index: 0;
}
.p-top-mv-image__inner {
}
.p-top-mv-image__item {
  img {
    @include mixin.vw(height, 1084, 750);
    @include mixin.object-fit(cover, top center);
    vertical-align: middle;

    @include mixin.mq-pc-min {
      height: 970px;
      /* height: auto; */
      width: 100vw;
    }
  }
}
.p-top-mv-text {
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1;
}
.p-top-mv-text__inner {
  @include mixin.vw(height, 1084, 750);
  position: relative;

  @include mixin.mq-pc-min {
    height: 970px;
  }
}
.p-top-mv-text__text1 {
  position: absolute;
  left: 0;
  @include mixin.vw(top, 180, 750);

  @include mixin.mq-pc-min {
    top: 295px;
  }

  img {
    @include mixin.vw(width, 585, 750);

    @include mixin.mq-pc-min {
      width: 503px;
    }
  }
}

.p-top-mv-text__text2 {
  @include mixin.vw(bottom, 60, 750);
  position: absolute;
  right: 0;

  @include mixin.mq-pc-min {
    left: 0;
    right: auto;
    /* top: 750px; */
    top: 700px
  }

  img {
    @include mixin.vw(width, 330, 750);

    @include mixin.mq-pc-min {
      width: 320px;
    }
  }
}

.p-top-support {
  background-color: variables.$COLOR_BLACK;
  color: variables.$COLOR_WHITE;
  padding-bottom: 75px;

  @include mixin.mq-pc-min {
    padding-bottom: 150px;
  }
}
.p-top-support-header {
  padding: 60px 0;

  @include mixin.mq-pc-min {
    padding: 120px 0;
  }
}
.p-top-support-header__title {
  font-family: variables.$FONT_MINCHO;
  @include mixin.fz(50/2);
  line-height: calc(73.5 / 50);
  margin-bottom: 15px;
  text-align: center;

  @include mixin.mq-pc-min {
    @include mixin.fz(32);
    line-height: 1;
    margin-bottom: 35px;

    br {
      display: none;
    }
  }
}
.p-top-support-header__description {
  line-height: calc(56 / 32);
  letter-spacing: 0.01em;

  @include mixin.mq-pc-min {
    line-height: 2;
    margin: 0 auto;
    max-width: 830px;
    text-align: center;
  }
}
.p-top-support-body {
}
.p-top-support-list {
  @include mixin.mq-pc-min {
    display: flex;
  }
}
.p-top-support-list__item {
  @include mixin.mq-pc-min {
    width: 33.33333%;
  }
}
.p-top-support-list__item:nth-child(n + 2) {
  margin-top: 40px;

  @include mixin.mq-pc-min {
    margin-top: 0;
  }
}
.p-top-support-card {
}
.p-top-support-card-image {
  margin-bottom: 16px;
  position: relative;

  @include mixin.mq-pc-min {
    margin-bottom: 30px;
  }

  &::before {
    background: rgba(variables.$COLOR_BLACK, 0.8);
    content: '';
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }

  img {
    @include mixin.vw(height, 398, 750);
    @include mixin.object-fit(cover, center center);

    @include mixin.mq-pc-min {
      height: 340px;
    }
  }
}
.p-top-support-card-image__title {
  align-items: center;
  display: flex;
  font-family: variables.$FONT_ALPHA;
  @include mixin.fz(25);
  font-weight: 500;
  height: 100%;
  left: 0;
  letter-spacing: 0.04em;
  line-height: 1.2;
  position: absolute;
  text-align: center;
  top: 0;
  width: 100%;

  @include mixin.mq-pc-min {
    @include mixin.fz(32);
    letter-spacing: 0.08em;
  }
}
.p-top-support-card-image__title-inner {
  display: block;
  width: 100%;
}
.p-top-support-card-image__title-small {
  @include mixin.fz(22.5);

  @include mixin.mq-pc-min {
    @include mixin.fz(27);
  }
}
.p-top-support-card-text {
  padding: 0 7.5px;
}
.p-top-support-card-text__description {
  letter-spacing: 0.1em;
  line-height: calc(56 / 32);

  @include mixin.mq-pc-min {
    margin: 0 auto;
    max-width: 400px;
    text-align: center;
    width: 100%;
  }
}
.p-top-support-card-text__buttons {
  margin-top: 20px;
  text-align: center;

  @include mixin.mq-pc-min {
    margin-top: 40px;
  }
}
.p-top-support-card-button {
  align-items: center;
  border: 1px solid currentColor;
  color: variables.$COLOR_WHITE;
  display: inline-flex;
  height: 50px;
  justify-content: center;
  text-align: center;
  text-decoration: none;
  width: 225px;

  @include mixin.mq-pc-min {
    height: 72px;
    width: 294px;

    &:hover {
      background-color: variables.$COLOR_WHITE;
      color: variables.$COLOR_BLACK;
      opacity: 1;
    }
  }
}

.p-top-reason {
  border-bottom: 1px solid variables.$COLOR_PRIMARY;
  border-top: 1px solid variables.$COLOR_PRIMARY;
  background: {
    color: variables.$COLOR_BLACK;
    image: url("#{mixin.set_image_path('top/bg_reason_sp.png')}");
    size: 100% auto;
    position: top center;
    repeat: no-repeat;
  }
  padding: 60px 0;

  @include mixin.mq-pc-min {
    background: {
      image: url("#{mixin.set_image_path('top/bg_reason_pc.png')}");
      size: contain;
      position: center left;
    }
    padding: 120px 0;
  }
}
.p-top-reason-header {
}
.p-top-reason-header__title {
  color: variables.$COLOR_WHITE;
  font-family: variables.$FONT_MINCHO;
  @include mixin.fz(20);
  line-height: 1.2;
  margin-bottom: 40px;
  text-align: center;

  @include mixin.mq-pc-min {
    @include mixin.fz(32);
    margin-bottom: 40px;
  }
}
.p-top-reason-header__title-large {
  @include mixin.fz(25);
  letter-spacing: 0.08em;

  @include mixin.mq-pc-min {
    @include mixin.fz(40);
  }
}
.p-top-reason-header__title-xLarge {
  @include mixin.fz(28);
  line-height: 1;

  @include mixin.mq-pc-min {
    @include mixin.fz(65);
  }
}

.p-top-reason-body {
}
.p-top-reason-list {
  @include mixin.mq-pc-min {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -15px;
  }
}
.p-top-reason-list__item {
  @include mixin.mq-pc-min {
    padding: 0 15px;
    width: 50%;
  }
}
.p-top-reason-list__item:nth-child(n + 2) {
  margin-top: 20px;

  @include mixin.mq-pc-min {
    margin-top: 0;
  }
}
.p-top-reason-list__item:nth-child(n + 3) {
  @include mixin.mq-pc-min {
    margin-top: 30px;
  }
}

.p-top-reason-card {
  background-color: rgba(variables.$COLOR_BLACK, 0.7);
  border: 1px solid currentColor;
  color: variables.$COLOR_GRAY1;
  padding: 15px 7.5px;

  @include mixin.mq-pc-min {
    height: 100%;
    padding: 30px;
  }
}
.p-top-reason-card-header {
  @include mixin.mq-pc-min {
    align-items: flex-end;
    display: flex;
    margin-bottom: 15px;
  }
}
.p-top-reason-card-header__number {
  margin-bottom: 5px;
  text-align: center;

  @include mixin.mq-pc-min {
    text-align: left;
    width: 60px;
  }

  img {
    height: 27px;

    @include mixin.mq-pc-min {
      height: 63px;
    }
  }
}
.p-top-reason-card-header__title {
  font-weight: 700;
  line-height: 1.4;
  margin-bottom: 10px;

  @include mixin.mq-pc-min {
    margin-bottom: 0;
    width: calc(100% - 60px);
  }
}
.p-top-reason-card-header__title-large {
  @include mixin.fz(21);

  @include mixin.mq-pc-min {
    @include mixin.fz(28);
  }
}
.p-top-reason-card-body {
}
.p-top-reason-card-body__description {
  line-height: calc(48 / 32);
}
.p-top-reason-card-body__link {
  margin-top: 15px;
  text-align: right;

  @include mixin.mq-pc-min {
    margin-top: 30px;
  }
}
.p-top-reason-card-body__link-target {
  color: currentColor;
  line-height: 1;
  padding-right: 15px;
  position: relative;
  text-decoration: none;

  &::after {
    background: {
      image: url("#{mixin.set_common_path('icon_arrow_gray1.svg')}");
      size: contain;
      repeat: no-repeat;
      position: center;
    }
    content: '';
    height: 10px;
    position: absolute;
    right: 0;
    top: 55%;
    transform: translateY(-50%);
    width: 7px;
  }
}

.p-top-contents {
  background-color: variables.$COLOR_BLACK;
  color: variables.$COLOR_WHITE;
  padding: 60px 0;

  @include mixin.mq-pc-min {
    padding: 120px 0;
  }
}
.p-top-contents-header {
}
.p-top-contents-header__title {
  font-family: variables.$FONT_MINCHO;
  @include mixin.fz(25);
  margin-bottom: 15px;
  text-align: center;

  @include mixin.mq-pc-min {
    @include mixin.fz(32);
    line-height: 1;
    margin-bottom: 40px;
  }
}
.p-top-contents-body {
}
.p-top-contents-banner1 {
}
.p-top-contents-banner1__inner {
}
.p-top-contents-banner1__item {
  padding: 0 28px;

  @include mixin.mq-pc-min {
    padding: 0 15px;
  }

  img {
    width: 100%;
  }
}
.p-top-contents-banner1__target {
  display: block;

  @include mixin.mq-pc-min {
    border: 1px solid variables.$COLOR_BLACK;

    &:hover {
      border-color: variables.$COLOR_WHITE;
      opacity: 1;
    }
  }
}

.p-top-contents-banner1__arrow {
  background: {
    color: variables.$COLOR_PRIMARY;
  }
  border: none;
  height: 35px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 35px;
  z-index: 1;

  &::after {
    background: {
      image: url("#{mixin.set_common_path('icon_arrow_white.svg')}");
      size: contain;
      position: center;
      repeat: no-repeat;
    }
    content: '';
    height: 14px;
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 8px;
  }
}
.p-top-contents-banner1__arrow--prev {
  left: 0;
  transform: translateY(-50%) rotate(180deg);
}
.p-top-contents-banner1__arrow--next {
  right: 0;
}

.p-top-contents-banner2 {
  margin-top: 43px;
  text-align: center;

  @include mixin.mq-pc-min {
    margin-top: 60px;
  }
}

.p-top-info {
  padding: 75px 0 60px;

  @include mixin.mq-pc-min {
    padding: 150px 0 120px;
  }
}
.p-top-info__inner {
  @include mixin.mq-pc-min {
    display: flex;
  }
}
.p-top-info-blog {
  margin-bottom: 60px;

  @include mixin.mq-pc-min {
    flex: 1;
    margin-bottom: 0;
    padding-right: 50px;
  }
}
.p-top-info-blog-header {
}
.p-top-info-blog-header__title {
  font-family: variables.$FONT_MINCHO;
  @include mixin.fz(25);
  line-height: 1;
  margin-bottom: 15px;
  text-align: center;

  @include mixin.mq-pc-min {
    @include mixin.fz(32);
    margin-bottom: 30px;
    text-align: left;
  }
}
.p-top-info-blog-body {
}
.p-top-info-blog-body__buttons {
  margin-top: 30px;
  text-align: center;

  @include mixin.mq-pc-min {
    text-align: left;
  }
}
.p-top-info-blog-list {
}
.p-top-info-blog-list__item {
}
.p-top-info-blog-list__item:nth-child(n + 1) {
  margin-top: 30px;

  @include mixin.mq-pc-min {
    margin-top: 0;
  }
}

.p-top-info-blog-list__item:nth-child(n + 2) {
  @include mixin.mq-pc-min {
    margin-top: 20px;
  }
}

.p-top-info-blog-list__target {
  display: block;
  text-decoration: none;

  @include mixin.mq-pc-min {
    display: flex;
  }
}
.p-top-info-blog-list__date {
  display: block;

  @include mixin.mq-pc-min {
    width: 115px;
  }
}
.p-top-info-blog-list__title {
  display: block;
  text-decoration: underline;

  @include mixin.mq-pc-min {
    width: calc(100% - 115px);
  }

  @at-root .p-top-info-blog-list__target:hover & {
    color: variables.$COLOR_LINK;
    text-decoration: none;
  }
}
.p-top-info-blog-button {
  align-items: center;
  border: 1px solid currentColor;
  color: variables.$COLOR_GREEN;
  display: inline-flex;
  height: 50px;
  justify-content: center;
  text-decoration: none;
  width: 225px;

  @include mixin.mq-pc-min {
    height: 72px;
    width: 294px;
  }
}
.p-top-info-blog-button__inner {
  padding-right: 20px;
  position: relative;

  &::after {
    background: {
      image: url("#{mixin.set_common_path('icon_newwindow_green.svg')}");
      size: contain;
      position: center;
      repeat: no-repeat;
    }
    content: '';
    height: 14px;
    position: absolute;
    right: 0;
    top: 55%;
    transform: translateY(-50%);
    width: 14px;
  }
}
.p-top-info-fb {
  @include mixin.mq-pc-min {
    width: 500px;
  }
}
