// base
$BASE: (
  color: hsl(0, 0%, 20%),
  color-link: hsl(0, 0%, 20%),
  font-size: 16,
  line-height: 1.6,
) !default;

// color
$COLOR: (
  strong: hsl(359, 75%, 57%),
  blue: hsl(204, 70%, 53%),
  green: hsl(168, 76%, 42%),
  white: hsl(0, 0%, 100%),
) !default;

$COLOR_PRIMARY: #333333;
$COLOR_BLACK: #000;
$COLOR_WHITE: #fff;
$COLOR_GRAY1: #dfdfdf;
$COLOR_GRAY2: #929292;
$COLOR_GRAY3: #1a1a1a;
$COLOR_GREEN: #2e863b;
$COLOR_LINK: #0068b3;

// brake-point
$BRAKE_POINT: (
  sp-wide: 601px,
  tablet: 769px,
  pc: 993px,
  pc-wide: 1201px,
);

// container-padding
$PADDING: (
  container: 15,
) !default;

// container-size
$CONTAINER: (
  sm: #{map_get($PADDING, container) * 2 + 750px},
  md: #{map_get($PADDING, container) * 2 + 970px},
  lg: #{map_get($PADDING, container) * 2 + 1170px},
) !default;

// font-family
$FONT_BASE: 'Noto Sans JP', -apple-system, BlinkMacSystemFont, 'メイリオ', Meiryo, 'Hiragino Kaku Gothic ProN',
  sans-serif;
$FONT_ALPHA: 'Rubik', Arial, Helvetica, sans-serif;
$FONT_MINCHO: 'Noto Serif JP', '游明朝体', 'Yu Mincho', 'ヒラギノ明朝 Pro W3', 'Hiragino Mincho Pro', serif;
$FONT_ICON: 'Material Icons';

// path
$PATH_IMAGE: '../img/';
$PATH_COMMON_IMAGE: '../img/common/';

@function set_image_path($filename) {
  @return #{$PATH_IMAGE} + #{$filename};
}
@function set_common_image_path($filename) {
  @return #{$PATH_COMMON_IMAGE} + #{$filename};
}
